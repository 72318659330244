/**
 * 格式化稿件时间
 * @param timestr
 * @returns
 */
const formatNewsTime = (timestr?: string): string => {
  if (!timestr) {
    return '';
  }
  const replaceTime = timestr.replace(/-/g, '/');
  const timestamp = new Date(replaceTime).getTime();
  const currentTimestamp = new Date().getTime();
  const h24Timestamp = 24 * 60 * 60 * 1000;
  const m60Timestamp = 60 * 60 * 1000;
  let result = '';
  if (currentTimestamp - timestamp < m60Timestamp) {
    const min = Math.ceil((currentTimestamp - timestamp) / (60 * 1000));
    result = `${min}分钟前`;
  } else if (currentTimestamp - timestamp < h24Timestamp) {
    const h = Math.ceil((currentTimestamp - timestamp) / (60 * 60 * 1000));
    result = `${h}小时前`;
  } else {
    const time = new Date(replaceTime);
    const cTime = new Date();
    const y = time.getFullYear();
    const cy = cTime.getFullYear();
    let m: number | string = time.getMonth() + 1;
    let d: number | string = time.getDate();
    m = m < 10 ? `0${m}` : m;
    d = d < 10 ? `0${d}` : d;
    if (y === cy) {
      result = `${m}月${d}日`;
    } else {
      result = `${y}年${m}月${d}日`;
    }
  }
  return result;
};

/**
 * 获取发布时间节点
 * @param isShowPublishTime
 * @param publishTime
 * @param lastpublishTime
 * @returns
 */
const getTimeDom = (
  isShowPublishTime = 0,
  publishTime = '',
  lastpublishTime = '',
) => {
  let newsTime = '';
  switch (isShowPublishTime) {
    case 1: {
      newsTime = formatNewsTime(publishTime);
      break;
    }
    case 2: {
      newsTime = formatNewsTime(lastpublishTime);
      break;
    }
    default: {
      break;
    }
  }
  const timeDiv = document.createElement('div');
  const timeText = document.createTextNode(newsTime);
  timeDiv.className = 't-news-time';
  timeDiv.appendChild(timeText);
  return timeDiv;
};

/**
 * 获取稿件来源节点
 * @param sourceName
 * @returns
 */
const getSourceDom = (sourceName?: string) => {
  const sourceDiv = document.createElement('div');
  sourceDiv.className = 't-news-source';
  if (sourceName) {
    const sourceText = document.createTextNode(sourceName);
    sourceDiv.appendChild(sourceText);
  } else {
    sourceDiv.style.display = 'none';
  }
  return sourceDiv;
};

/**
 * 获取标题节点
 * @param title
 * @returns
 */
const getTitleDom = (title: string) => {
  const titleDiv = document.createElement('div');
  titleDiv.className = 't-news-title';
  const titleText = document.createTextNode(title);
  titleDiv.appendChild(titleText);
  return titleDiv;
};

/**
 * 获取图片节点
 * @param src
 * @returns
 */
const getImageDom = (src: string) => {
  const image = new Image();
  image.className = 't-news-image lazyload';
  image.src = process.env.DEFAULT_IMAGE_16_9 as string;
  image.onerror = () => {
    image.src = process.env.DEFAULT_IMAGE_16_9 as string;
  };
  image.setAttribute('data-src', src);
  return image;
};

const getVideoDom = (id: string, src: string, poster?: string) => {
  const video = document.createElement('video');
  if (src) {
    video.setAttribute('src', src);
  }
  if (poster) {
    video.setAttribute('poster', poster);
  }
  video.setAttribute('webkit-playsinline', 'true');
  video.setAttribute('playsinline', 'true');
  video.setAttribute('x5-video-player-type', 'h5');
  video.className = 'video-js t-news-video';
  video.id = `video-${id.substring(0, 5)}`;
  return video;
};

/**
 * 创建链接节点
 * @param url
 * @returns
 */
const getLinkDom = (url: string) => {
  const aDom = document.createElement('a');
  aDom.setAttribute('href', url);
  aDom.setAttribute('target', '_blank');
  aDom.className = 't-news-link';
  aDom.addEventListener('click', (event) => {
    if ((event.target as HTMLElement).className.includes('vjs')) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
    return true;
  });
  return aDom;
};

/**
 * 获取稿件节点
 * @param news
 * @returns
 */
const initNewsTemplate = (news: RMT.NewsData) => {
  const titleDom = getTitleDom(news.title);
  const timeDom = getTimeDom(
    news.isShowPublishTime,
    news.publishTime,
    news.lastpublishTime,
  );
  const sourceDom = getSourceDom(news.sourceName);
  const sourceTimeDom = document.createElement('div');
  sourceTimeDom.className = 't-news-source-time';
  sourceTimeDom.appendChild(sourceDom);
  sourceTimeDom.appendChild(timeDom);
  if (!news.sourceName && news.isShowPublishTime === 0) {
    sourceTimeDom.style.display = 'none';
  }
  const newsBoxDom = document.createElement('div');
  newsBoxDom.className = `t-news-box t-news-pattern-${news.mListpattern}`;
  const linkDom = getLinkDom(news.url as string);
  linkDom.appendChild(newsBoxDom);

  if (news.mListpattern === 1) {
    const imageDom = getImageDom(news.mCoverImg_s || news.mCoverImg);
    const leftDom = document.createElement('div');
    leftDom.className = 't-news-left';
    leftDom.appendChild(titleDom);
    leftDom.appendChild(sourceTimeDom);
    const rightDom = document.createElement('div');
    rightDom.className = 't-news-right';
    rightDom.appendChild(imageDom);
    newsBoxDom.appendChild(leftDom);
    newsBoxDom.appendChild(rightDom);
  } else if (
    news.mListpattern === 2 ||
    news.mListpattern === 7 ||
    news.mListpattern === 8 ||
    news.mListpattern === 10
  ) {
    if (news.moVideoOsspath || news.moVideoPath) {
      const videoDom = getVideoDom(
        news.id,
        (news.moVideoOsspath || news.moVideoPath) as string,
        news.mCoverImg_s || news.mCoverImg,
      );
      newsBoxDom.appendChild(videoDom);
    } else {
      const imageDom = getImageDom(news.mCoverImg_s || news.mCoverImg);
      newsBoxDom.appendChild(imageDom);
    }
    newsBoxDom.appendChild(titleDom);
    newsBoxDom.appendChild(sourceTimeDom);
  } else if (news.mListpattern === 3) {
    const imageListDom = document.createElement('div');
    imageListDom.className = 't-news-image-list';
    const imageDom1 = getImageDom(news.mCoverImg1_s || news.mCoverImg1);
    const imageDom2 = getImageDom(news.mCoverImg2_s || news.mCoverImg2);
    const imageDom3 = getImageDom(news.mCoverImg3_s || news.mCoverImg3);
    imageListDom.appendChild(imageDom1);
    imageListDom.appendChild(imageDom2);
    imageListDom.appendChild(imageDom3);
    newsBoxDom.appendChild(imageListDom);
    newsBoxDom.appendChild(titleDom);
    newsBoxDom.appendChild(sourceTimeDom);
  } else if (news.mListpattern === 4) {
    newsBoxDom.appendChild(titleDom);
    newsBoxDom.appendChild(sourceTimeDom);
  } else if (news.mListpattern === 5 || news.mListpattern === 6) {
    const imageListDom = document.createElement('div');
    imageListDom.className = 't-news-image-list';
    const imageDom1 = getImageDom(news.mCoverImg1_s || news.mCoverImg1);
    const imageDom2 = getImageDom(news.mCoverImg2_s || news.mCoverImg2);
    imageListDom.appendChild(imageDom1);
    imageListDom.appendChild(imageDom2);
    if (news.mListpattern === 6) {
      const imageDom3 = getImageDom(news.mCoverImg3_s || news.mCoverImg3);
      const imageDom4 = getImageDom(news.mCoverImg4_s || news.mCoverImg4);
      imageListDom.appendChild(imageDom3);
      imageListDom.appendChild(imageDom4);
    }
    newsBoxDom.appendChild(imageListDom);
    newsBoxDom.appendChild(titleDom);
    newsBoxDom.appendChild(sourceTimeDom);
  } else if (news.mListpattern === 9) {
    const image =
      news.mTopicLogo_s ||
      news.mTopicLogo ||
      news.mCoverImg_s ||
      news.mCoverImg ||
      news.mCoverImg1_s ||
      news.mCoverImg1;
    if (image) {
      const imageDom = getImageDom(image);
      newsBoxDom.appendChild(imageDom);
    }
    newsBoxDom.appendChild(titleDom);
    newsBoxDom.appendChild(sourceTimeDom);
  } else {
    newsBoxDom.className = 't-news-box t-news-pattern-4';
    newsBoxDom.appendChild(titleDom);
    newsBoxDom.appendChild(sourceTimeDom);
  }
  return linkDom;
};

export default initNewsTemplate;
