import { fetchLogRecord, fetchWxConfig } from '../apis/api';
import { browerVersion, getSingleValueFromInput, loadScript } from './utils';

/**
 * 获取打开app/引导下载按钮列表
 * @param list
 * @returns
 */
const getLaunchAppBtn = (list: LaunchAppBtn[]) => {
  const domList: HTMLElement[] = [];
  list.forEach((item) => {
    const { attr, value } = item;
    switch (attr) {
      case 'id': {
        const dom = document.getElementById(value);
        if (dom) {
          domList.push(dom);
        }
        break;
      }
      case 'class': {
        const doms = document.getElementsByClassName(value);
        for (let i = 0; i < doms.length; i += 1) {
          domList.push(doms[i] as HTMLElement);
        }
        break;
      }
      case 'name': {
        const doms = document.getElementsByName(value);
        for (let i = 0; i < doms.length; i += 1) {
          domList.push(doms[i] as HTMLElement);
        }
        break;
      }
      default: {
        break;
      }
    }
  });
  return domList;
};

/**
 * 监听按钮的点击事件
 * @param domList
 * @returns
 */
const initButtonClick = (domList: HTMLElement[]) => {
  const version = browerVersion();
  if (version.myApp) {
    return;
  }
  // 获取打开APP的参数
  const launchData = {
    id: getSingleValueFromInput('newsId') || getSingleValueFromInput('mediaId'),
    contentType: getSingleValueFromInput('contentType'),
    isChild: Number(getSingleValueFromInput('isChild')) || 0,
    mListpattern: getSingleValueFromInput('mListpattern') || 0,
  };
  let downloadLink = '';
  let url = '';
  let timeout = 0;
  // 通过隐藏的iframe调起APP
  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  window.addEventListener('visibilitychange', () => {
    if (document.hidden && timeout) {
      clearTimeout(timeout);
      iframe.remove();
    }
  });
  // 通过遍历监听点击事件
  domList.forEach((dom) => {
    dom.addEventListener('click', () => {
      // 获取每个点击按钮内部的下载链接
      const aList = dom.getElementsByTagName('a');
      for (let i = 0; i < aList.length; i += 1) {
        const aItem = aList[i];
        if (aItem.dataset.href) {
          downloadLink = aItem.dataset.href || '';
          const innerNewsId = aItem.dataset.id;
          const innerContentType = aItem.dataset.type;
          const innerMListpattern = aItem.dataset.pattern;
          if (innerNewsId && innerContentType) {
            launchData.id = innerNewsId;
            launchData.contentType = innerContentType;
            launchData.mListpattern = innerMListpattern || 0;
          }
          break;
        }
      }
      if (version.android) {
        // 安卓通过ifream打开app的链接
        url = `${process.env.ANDROID_LAUNCH_APP_LINK}?data=${JSON.stringify(
          launchData,
        )}`;
        iframe.src = url;
        document.body.append(iframe);
      } else if (version.iPhone || version.iPad) {
        // ios通过location.href打开app的链接
        url = `${process.env.IOS_LAUNCH_APP_LINK}?data=${JSON.stringify(
          launchData,
        )}`;
        window.location.href = url;
      } else if (downloadLink) {
        // 其他浏览器打开下载链接
        window.location.href = downloadLink;
      }
      // 尝试打开APP，若打开失败则打开下载页面
      if (url) {
        timeout = window.setTimeout(() => {
          if (downloadLink) {
            window.location.href = downloadLink;
          }
          iframe.remove();
        }, 3000);
      }
    });
  });
};

/**
 * 非微信显示打开app/下载引导条
 * @param list
 */
const initLaunchAppBtn = (list: LaunchAppBtn[]) => {
  const btnDomList: HTMLElement[] = getLaunchAppBtn(list);
  if (!btnDomList || !btnDomList.length) {
    return;
  }
  btnDomList.forEach((dom) => {
    // eslint-disable-next-line no-param-reassign
    dom.style.display = 'block';
    if (dom.className.includes('header-guide-box')) {
      const containerDom = document.querySelector('.container');
      if (containerDom) {
        containerDom.scrollIntoView();
      }
    }
  });
  initButtonClick(btnDomList);
};

const initWxLaunchAppBtnEvent = (dom: HTMLElement, downloadLink: string) => {
  dom.addEventListener('ready', () => {});
  dom.addEventListener('error', (evt: any) => {
    fetchLogRecord(evt.detail);
    console.error(evt.detail);
    if (downloadLink) {
      window.location.href = downloadLink;
    }
  });
};

/**
 * 微信显示打开app/下载引导条
 * @param list
 */
const initWxLaunchAppBtn = (list: LaunchAppBtn[]) => {
  const version = browerVersion();
  // ios端可直接打开
  if (version.iPhone || version.iPad) {
    initLaunchAppBtn(list);
  } else if (version.android) {
    const launchData = {
      id:
        getSingleValueFromInput('newsId') || getSingleValueFromInput('mediaId'),
      contentType: getSingleValueFromInput('contentType'),
      isChild: Number(getSingleValueFromInput('isChild')) || 0,
      mListpattern: getSingleValueFromInput('mListpattern') || 0,
    };
    const appAppid = process.env.WX_LAUNCH_APP_APPID;
    const btnDomList: HTMLElement[] = getLaunchAppBtn(list);
    if (!btnDomList || !btnDomList.length) {
      return;
    }
    const launchAppBtnList = btnDomList.map((item) => {
      let launchAppBtn;
      for (let i = 0; i < item.children.length; i += 1) {
        if (item.children[i].getAttribute('name') === 'launch-app-btn') {
          launchAppBtn = item.children[i];
        }
      }
      return launchAppBtn;
    });
    launchAppBtnList.forEach((el, index) => {
      if (!el) {
        return;
      }
      const parent = el.parentNode;
      // 获取style标签
      let downloadLink = '';
      const extinfo: string | null = '';
      if (el.children[0].getAttribute('name') === 'download-link') {
        downloadLink = el.children[0].getAttribute('data-href') as string;
        const innerNewsId = el.children[0].getAttribute('data-id');
        const innerContentType = el.children[0].getAttribute('data-type');
        const innerMListpattern = el.children[0].getAttribute('data-pattern');
        if (innerNewsId && innerContentType) {
          launchData.id = innerNewsId;
          launchData.contentType = innerContentType;
          launchData.mListpattern = innerMListpattern || 0;
        }
      }
      const { style: styleName } = (el as HTMLElement).dataset;
      let style = document.createElement('style');
      if (styleName) {
        const styles = document.getElementsByName(styleName);
        if (styles && styles.length) {
          style = styles[0] as HTMLStyleElement;
        }
      }
      // 创建打开app的点击区间，点击区间内的宽高必须提前设定
      const wxOpenLaunchAppDom = document.createElement('wx-open-launch-app');
      wxOpenLaunchAppDom.id = `${el.className}-${index}`;
      wxOpenLaunchAppDom.setAttribute('appid', `${appAppid}`);
      wxOpenLaunchAppDom.setAttribute(
        'extinfo',
        `${extinfo || JSON.stringify(launchData)}`,
      );
      const scriptDom = document.createElement('script');
      scriptDom.type = 'text/wxtag-template';
      scriptDom.appendChild(style.cloneNode(true));
      scriptDom.appendChild(el);
      wxOpenLaunchAppDom.appendChild(scriptDom);
      if (parent) {
        parent.appendChild(wxOpenLaunchAppDom);
        (parent as HTMLElement).style.display = 'block';
        if ((parent as HTMLElement).className.includes('header-guide-box')) {
          const containerDom = document.querySelector('.container');
          if (containerDom) {
            containerDom.scrollIntoView();
          }
        }
        initWxLaunchAppBtnEvent(wxOpenLaunchAppDom, downloadLink);
      }
    });
  } else {
    initLaunchAppBtn(list);
  }
};

const checkJsApiResultFormat = (value: string | boolean) => {
  if (typeof value === 'string' && value === 'true') {
    return true;
  }
  if (typeof value === 'boolean' && value === true) {
    return true;
  }
  return false;
};

let isFinishConfig = false;
let wxShareData: { title: string; desc: string; imgUrl: string } | null;
const initWxShare = (wxShare: WxShare) => {
  const { title, desc, imgUrl } = wxShare;
  const link = wxShare.link || window.location.href;
  const shareAppMessage = {
    title,
    desc,
    link,
    imgUrl,
    fail: (err: any) => {
      fetchLogRecord({ ...err, link, imgUrl });
    },
  };
  const timelineMessage = {
    title,
    link,
    imgUrl,
    fail: (err: any) => {
      fetchLogRecord({ ...err, link, imgUrl });
    },
  };
  wx.checkJsApi({
    jsApiList: [
      'updateAppMessageShareData',
      'updateTimelineShareData',
      'onMenuShareAppMessage',
      'onMenuShareTimeline',
    ],
    success: (res: any) => {
      if (!res || !res.checkResult) {
        fetchLogRecord({ msg: res });
        wx.updateAppMessageShareData(shareAppMessage);
        wx.updateTimelineShareData(timelineMessage);
        return;
      }
      const {
        updateAppMessageShareData,
        onMenuShareAppMessage,
        updateTimelineShareData,
        onMenuShareTimeline,
      } = res.checkResult;
      if (checkJsApiResultFormat(updateAppMessageShareData)) {
        wx.updateAppMessageShareData(shareAppMessage);
      } else if (checkJsApiResultFormat(onMenuShareAppMessage)) {
        wx.onMenuShareAppMessage(shareAppMessage);
      }
      if (checkJsApiResultFormat(updateTimelineShareData)) {
        wx.updateTimelineShareData(timelineMessage);
      } else if (checkJsApiResultFormat(onMenuShareTimeline)) {
        wx.onMenuShareTimeline(timelineMessage);
      }
    },
    fail: (err: any) => {
      fetchLogRecord({ msg: 'checkJsApi fail', ...err });
      wx.updateAppMessageShareData(shareAppMessage);
      wx.updateTimelineShareData(timelineMessage);
    },
  });
};

const initWxConfig = async () => {
  const scriptPromise = new Promise((resolve) => {
    loadScript({
      url: 'https://res.wx.qq.com/open/js/jweixin-1.6.0.js',
      name: 'wxJsSdk',
      loadFn: () => {
        resolve(true);
      },
    });
  });

  return new Promise((resolve) => {
    Promise.all([fetchWxConfig(), scriptPromise]).then((reslist) => {
      const [fetchRes] = reslist;
      const version = browerVersion();
      wx.config({
        appId: fetchRes.appid,
        timestamp: fetchRes.timestamp,
        nonceStr: fetchRes.nonceStr,
        signature: fetchRes.signature,
        jsApiList: [
          'updateAppMessageShareData',
          'updateTimelineShareData',
          'onMenuShareAppMessage',
          'onMenuShareTimeline',
        ],
        openTagList: ['wx-open-launch-app'],
      });
      wx.ready(() => {
        if (wxShareData) {
          initWxShare(wxShareData);
          wxShareData = null;
          isFinishConfig = true;
        }
        resolve(true);
      });
      wx.error((err: any) => {
        fetchLogRecord({
          ...err,
          ...fetchRes,
          wxWork: version.wxWork,
          wxVersion: version.wxVersion,
        });
        resolve(false);
      });
    });
  });
};

const initWxSdk = async (options: InitWxSdk) => {
  const { host } = window.location;
  const version = browerVersion();
  if (version.myApp) {
    return;
  }
  const { wxShare, launchAppBtn } = options;
  // 不在微信中，需要显示按钮时
  if (!version.weixin && launchAppBtn) {
    initLaunchAppBtn(launchAppBtn);
    return;
  }
  // 在微信中，但是域名不是已经配置的域名，但是需要显示按钮时
  if (
    version.weixin &&
    launchAppBtn &&
    host !== process.env.WX_OPEN_LAUNCH_APP &&
    version.mobile
  ) {
    initLaunchAppBtn(launchAppBtn);
  }
  if (
    version.weixin &&
    launchAppBtn &&
    host === process.env.WX_OPEN_LAUNCH_APP &&
    version.mobile
  ) {
    initWxLaunchAppBtn(launchAppBtn);
  }
  if (version.weixin) {
    if (wxShare && !isFinishConfig) {
      wxShareData = { ...wxShare };
    } else if (wxShare && isFinishConfig) {
      initWxShare(wxShare);
    }
    if (!document.getElementsByName('wxJsSdk').length) {
      initWxConfig();
    }
  }
};

export default initWxSdk;
